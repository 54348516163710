import HeroImage from "../images/HeroImage.svg";
import SubheroImage from "../images/SubheroImage.svg";
import SRT_CoreConstruction from "../images/customerLogos/SRT_CoreConstruction.svg";
import SRT_WalshDupart from "../images/customerLogos/SRT_WalshDupart.png";
import SRT_ViniferaHomes from "../images/customerLogos/SRT_ViniferaHomes.webp";
import SRT_SimpsonCapital from "../images/customerLogos/SRT_SimpsonCapital.png";
import GCS_Synergyn from "../images/customerLogos/GCS_Synergyn.png";
import GCS_ArrangedAbode from "../images/customerLogos/GCS_ArrangedAbode.png";
import GCS_PistlConsortia from "../images/customerLogos/GCS_PistlConsortia.png";
import AppInfo from "../components/home/AppInfo";
import Value from "../components/home/Value";
import ReliabilityImage from "../images/values/Reliability.svg";
import EfficiencyImage from "../images/values/Efficiency.svg";
import CustomerObsessionImage from "../images/values/CustomerObsession.svg";
import FindingImage from "../images/Finding.svg";
import { Link } from "react-router-dom";
import apps from "../data/apps";

const logos = [
  SRT_CoreConstruction,
  SRT_WalshDupart,
  SRT_ViniferaHomes,
  SRT_SimpsonCapital,
  GCS_Synergyn,
  GCS_ArrangedAbode,
  GCS_PistlConsortia,
];

const Home = () => {
  return (
    <main className="overflow-x-hidden">
      <section className="bg-primary-dark">
        <div className="container flex justify-between items-center py-24">
          <div className="w-[580px] flex flex-col gap-8">
            <h1 className="text-white">Powerful Apps For Supercharging Your Workflows</h1>
            <p className="p1-b text-white">
              Take your monday.com workflows to the next level with our apps
            </p>
          </div>
          <div>
            <img src={HeroImage} />
          </div>
        </div>
      </section>

      <div className="marquee w-fit py-12 pr-16 overflow-hidden bg-white">
        <div className="flex gap-16">
          {/* Logos have been repeated for proper scrolling effect */}
          {[...logos, ...logos].map((logo, index) => (
            <div className="w-[240px] flex justify-center items-center" key={index}>
              <img className="h-12 w-auto" src={logo} />
            </div>
          ))}
        </div>
      </div>

      <section className="bg-secondary-light">
        <div className="container flex justify-between items-center py-24">
          <div className="w-[580px] flex flex-col gap-10">
            <h2 className="text-primary-dark">Simplifying Work, Amplifying Results</h2>
            <p className="p2-b text-primary-dark">
              At Super Workday, we create smart, intuitive apps designed to enhance your monday.com
              experience. From automating recurring tasks to seamless integrations, our mission is
              to simplify workflows and help teams achieve more, faster.
              <br />
              <br />
              Trusted by forward-thinking businesses worldwide, we're here to make every workday
              super productive.
            </p>
          </div>
          <img src={SubheroImage} />
        </div>
      </section>

      <section id="apps" className="bg-primary-dark">
        <div className="container flex flex-col gap-10 py-20">
          <h2 className="text-white">Our Apps</h2>
          <div className="flex flex-col gap-24">
            {Object.values(apps).map(({ name, introText, image }, index) => (
              <AppInfo
                name={name}
                description={introText}
                image={image}
                reverse={index % 2 !== 0}
              />
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="container flex flex-col gap-10 py-20">
          <h2 className="text-primary">Our Values</h2>
          <div className="flex flex-col">
            <Value
              name="Reliability"
              description="Your workflows deserve tools you can trust. At Super Workday, we build apps that work seamlessly, ensuring every task is completed without a hitch. Count on us for reliability, so you can focus on what matters most."
              image={ReliabilityImage}
            />
            <Value
              name="Efficiency"
              description="Simplify your work processes and watch productivity soar. Our apps are designed to streamline recurring tasks, automate workflows, and sync seamlessly—giving you more time to achieve your goals."
              image={EfficiencyImage}
              reverse={true}
            />
            <Value
              name="Customer Obsession"
              description="Your feedback drives us forward. Whether it’s syncing tools or adding custom automations, we’re here to solve the challenges that matter most to you. Super Workday evolves with your needs."
              image={CustomerObsessionImage}
            />
          </div>
        </div>
      </section>

      <section id="support" className="bg-secondary-light">
        <div className="container flex justify-between items-center gap-20 py-10">
          <div className="w-[550px] flex flex-col gap-20">
            <h3 className="text-primary-dark">Looking for something specific?</h3>
            <div className="flex gap-5">
              <Link
                to="mailto:support@superworkday.com"
                target="_blank"
                className="p2-b flex-1 p-4 text-center text-white bg-primary rounded-lg"
              >
                Contact Us
              </Link>
              <Link
                to="https://calendly.com/superworkday/app-demo"
                target="_blank"
                className="p2-b flex-1 p-4 text-center text-white bg-primary rounded-lg"
              >
                Book Demo
              </Link>
            </div>
          </div>
          <div>
            <img src={FindingImage} />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
