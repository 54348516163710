const Feature = ({ name, description, reverse, image }) => {
  return (
    <div className={`flex justify-between items-center py-8 ${reverse ? "flex-row-reverse" : ""}`}>
      <div className="w-[45%]">
        <img src={image} alt={name} className="rounded-lg shadow-lg" />
      </div>
      <div className="w-[45%] flex flex-col gap-5">
        <p className="p1-b text-primary">{name}</p>
        <p className="p2-b text-primary-dark">{description}</p>
      </div>
    </div>
  );
};

export default Feature;
