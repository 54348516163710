import { FaLinkedin, FaYoutube } from "react-icons/fa";
import Logo from "../images/Logo.svg";
import FooterList from "./FooterList";

const appLinks = [
  { name: "Schedule Recurring Tasks", link: "/schedule-recurring-tasks" },
  { name: "Google Calendar Sync", link: "/google-calendar-sync" },
  { name: "Special Automations", link: "/special-automations" },
];

const legalLinks = [
  { name: "Terms of Use", link: "/terms" },
  { name: "Privacy Policy", link: "/privacy" },
];

const supportLinks = [
  { name: "Contact Us", link: "mailto:support@superworkday.com" },
  { name: "Book Demo", link: "https://calendly.com/superworkday/app-demo" },
];

const Footer = () => {
  return (
    <footer className="flex flex-col gap-5 bg-primary-dark border-primary-light py-10">
      <div className="container flex justify-between items-start py-10">
        <img src={Logo} />
        <FooterList title="Apps" links={appLinks} />
        <FooterList title="Legal" links={legalLinks} shorter />
        <FooterList title="Support" links={supportLinks} shorter />
      </div>

      <div className="h-[1px] bg-primary-light"></div>

      <div className="container flex justify-between items-center">
        <p className="p4-r text-white">
          Copyright © {new Date().getFullYear()} Super Workday. All rights reserved.
        </p>
        <div className="flex items-center gap-5">
          <a href="https://www.youtube.com/@SuperWorkdayApps" target="_blank" rel="noreferrer">
            <FaYoutube className="w-[40px] h-[40px] text-white" />
          </a>
          <a
            href="https://www.linkedin.com/company/super-workday/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="w-[30px] h-[30px] text-white" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
