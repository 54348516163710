import { useEffect } from "react";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-secondary-light">
      <div className="container py-20">
        <div className="flex flex-col justify-center items-center gap-2 pb-20">
          <h2 className="text-primary-dark">Privacy Policy</h2>
          <p className="p3-b text-secondary-dark">Last Updated: 15 April 2024</p>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Privacy Policy</p>
            <p className="p2-r text-secondary-dark">
              At Super Workday, we respect your privacy and are committed to protecting it. By using
              any of our application, you agree to the terms outlined in this document. Currently,
              our apps include the following:
            </p>
            <ul className="p2-r text-secondary-dark">
              <li>{"->"} Schedule Recurring Tasks</li>
              <li>{"->"} Google Calendar Sync</li>
              <li>{"->"} Special Automations</li>
            </ul>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Limited Use Disclosure</p>
            <p className="p2-r text-secondary-dark">
              For the app "Calendar Sync" use and transfer of information received from Google APIs
              to any other app will adhere to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
                className="text-blue-600"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Data from Google APIs</p>
            <p className="p2-r text-secondary-dark">
              For the app "Calendar Sync" data is collected from Google APIs (particularly Google
              Calendar API) for the core app functionality. Such data includes metadata about list
              of your calendars and calendar events.
            </p>
            <p className="p2-r text-secondary-dark">
              The data obtained is not used for any other purpose apart from serving the core
              features of the app, which is syncing board and calendar. The relevant data required
              is stored in our internal servers hosted in Google Cloud Platform. Such data is not
              shared with any third party for any purpose. Further any such stored data is deleted
              automatically when the user/account uninstalls the app.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">What information do we collect?</p>
            <p className="p2-r text-secondary-dark">
              We collect and receive the following categories of information, which may be
              considered Personal Information when maintained in an identifiable format:
            </p>
            <ul className="flex flex-col gap-4 p2-r text-secondary-dark">
              <li>
                {"->"} Purchase Information: When you purchase our Products or Services, we may
                collect your full name, email address, marketplace account id, and if applicable,
                information related to your organization.
              </li>
              <li>
                {"->"} Content and information voluntarily provided through the Services: Any
                information you voluntarily provide to us through the Services will be collected.
              </li>
              <li>
                {"->"} Information collected from third-party or public sources: We may receive
                information from third-party sources, such as business partners, Marketplace
                Partners, or publicly available sources. This information may include an authorized
                user's name, email address, and phone number.
              </li>
              <li>
                {"->"} Technical product usage data, logs, metrics, metadata, and device
                information: We automatically generate and retain records of user interactions with
                our Sites and Products. This may include information such as IP addresses, device
                identifiers, device information (e.g., OS type or browser type), referring/exit
                pages and URLs, clickstream data, domain names, pages viewed, crash data, and other
                similar technical data.
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">How we use the collected information?</p>
            <p className="p2-r text-secondary-dark">
              We only collect information that is reasonably necessary to respond to inquiries about
              our Services, provide you with the Services, carry out our operations, comply with
              legal requirements, and for other legitimate business purposes under applicable laws.
              Such information is collected through usage of our apps which are hosted on the Google
              Cloud Platform. The following are the purposes for which we may use your Personal
              Information:
            </p>
            <ul className="flex flex-col gap-4 p2-r text-secondary-dark">
              <li>
                {"->"} Informing you about the Services: We may use your Personal Information to
                inform you about the Services in which you have expressed interest or to which you
                have subscribed. This includes fulfilling transactions, providing customer support,
                operating, maintaining, and improving the Services, and sending notices about your
                account and/or subscription, including expiration and renewal notices.
              </li>
              <li>
                {"->"} Business development and marketing: We may use your Personal Information to
                assist in business development, general marketing, and building relationships. This
                may involve creating lists of actual and prospective users and companies, compiling
                statistics to improve our Services, and contacting you with information that may be
                of interest to you.
              </li>
              <li>
                {"->"} Record-keeping and account management: We may record your name, contact
                information, and other Personal Information for legitimate business purposes to
                provide our Services and manage your account. This includes authorizing and managing
                access to the Sites/Products, performing our contract with a Customer or
                Administrator, and responding to support-related requests.
              </li>
              <li>
                {"->"} Improving the Sites and Services: We may use your personal data to improve
                the layout of our Sites based on your click path and interactions within the Sites,
                thereby providing you with better services.
              </li>
              <li>
                {"->"} Legal obligations and security: We may use your Personal Information to
                comply with legal obligations and professional responsibilities. This includes
                verifying accounts and activity, detecting and preventing security incidents, and
                monitoring and protecting against malicious, deceptive, fraudulent, or illegal
                activity.
              </li>
            </ul>
            <p className="p2-r text-secondary-dark">
              If are using our Services and thus have provided consent for using your data, you can
              change your mind at any time. However, this will not affect any processing that has
              already taken place. You have the right to object to any processing of information,
              but it may result in you no longer using the Services. To object to processing based
              on legitimate interests, please contact us at support@superworkday.com.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">How long do we keep the information?</p>
            <p className="p2-r text-secondary-dark">
              We will retain your Personal Information for the period necessary to fulfill the
              purposes outlined in this Privacy Policy. We will also retain your information to
              comply with our legal obligations, to conduct audits, resolve disputes, and enforce
              our agreements.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Privacy and Security</p>
            <p className="p2-r text-secondary-dark">
              We have implemented reasonably adequate privacy and security measures to safeguard
              your Personal Information from loss, misuse, unauthorized access, disclosure,
              alteration, and destruction. These measures include compliance with privacy and
              security requirements of Marketplace Partners. We utilize a self-assessment approach
              to ensure compliance with our privacy statements and regularly verify the accuracy and
              completeness of our statements regarding the handling and use of Personal Information.
            </p>
            <p className="p2-r text-secondary-dark">
              While we strive to protect your Personal Information, it is important to note we
              cannot guarantee the security of any information transmitted to us through online
              forms, applications, or chat rooms. Such transmission is done at your own risk. Email
              communication is considered non-encrypted and non-secure, and it can be accessed and
              viewed by others without your knowledge or permission. Therefore, to protect your
              privacy, we advise against using email to communicate confidential information. No
              data transmission or storage system can be guaranteed to be completely secure at all
              times. If you believe that your interaction with us is no longer secure, please
              promptly notify us at support@superworkday.com. We will promptly inform you if a
              privacy or security breach occurs that may have compromised your information.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Changes to this Privacy Policy</p>
            <p className="p2-r text-secondary-dark">
              Any changes we make to our privacy policy will be posted on this page. We encourages
              visitors to frequently check this page for any changes to the Privacy Policy.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Contact Us</p>
            <p className="p2-r text-secondary-dark">
              For any questions or concerns about our privacy policy, please contact us at{" "}
              <a href="mailto:support@superworkday.com" className="text-blue-600">
                support@superworkday.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Privacy;
