const Value = ({ name, description, reverse, image }) => {
  return (
    <div
      className={`flex justify-between items-center px-15 py-8 ${
        reverse ? "flex-row-reverse" : ""
      }`}
    >
      <div>
        <img src={image} alt={name} />
      </div>
      <div className="w-[600px] flex flex-col gap-5">
        <h3 className="text-primary">{name}</h3>
        <p className="p2-b text-primary-dark">{description}</p>
      </div>
    </div>
  );
};

export default Value;
