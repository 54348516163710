import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../images/Logo.svg";

const Header = () => {
  return (
    <header className="bg-primary-dark border-b-2 border-primary-light">
      <div className="container flex justify-between items-center py-4">
        <Link to="/">
          <div>
            <img src={Logo} />
          </div>
        </Link>
        <div className="flex justify-between gap-8">
          <HashLink smooth to="/#apps" className="p2-b text-white">
            Apps
          </HashLink>
          <HashLink smooth to="/#support" className="p2-b text-white">
            Support
          </HashLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
