import React from "react";
import { Link } from "react-router-dom";

const FooterList = ({ title, links, shorter }) => {
  return (
    <div className={`${shorter ? "w-[200px]" : "w-[250px]"} flex flex-col gap-5`}>
      <p className="p2-b text-white">{title}</p>
      <ul className="flex flex-col gap-2">
        {links.map(({ name, link }) => (
          <li key={name} className="p3-b text-white">
            <Link
              to={link}
              target={
                link.startsWith("mailto:") || link.startsWith("https://") ? "_blank" : "_self"
              }
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterList;
