import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-secondary-light">
      <div className="container py-20">
        <div className="flex flex-col justify-center items-center gap-2 pb-20">
          <h2 className="text-primary-dark">Terms of Service</h2>
          <p className="p3-b text-secondary-dark">Last Updated: 05 March 2024</p>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Terms of Service</p>
            <p className="p2-r text-secondary-dark">
              Welcome to Super Workday. This document serves as a legally binding agreement between
              you (the user) and us (the company).
            </p>
            <p className="p2-r text-secondary-dark">
              The terms “us”, “we”, “our”, and “the company” refer to our application. The term
              “you” refers to the user of our application.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Acceptance of Terms</p>
            <p className="p2-r text-secondary-dark">
              By using any of our application, you agree to the terms and conditions outlined in
              this document. Currently, our apps include the following:
            </p>
            <ul className="p2-r text-secondary-dark">
              <li>{"->"} Schedule Recurring Tasks</li>
              <li>{"->"} Google Calendar Sync</li>
              <li>{"->"} Special Automations</li>
            </ul>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Data Collection And Usage</p>
            <p className="p2-r text-secondary-dark">
              Please consult our Privacy Policy to learn about the information we collect and how it
              is used.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Changes to the Terms</p>
            <p className="p2-r text-secondary-dark">
              We reserve the right to modify these terms at any time. We will notify you of any
              changes, and your continued use of our application after such notification will
              constitute your acceptance of the revised terms.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <p className="p1-b text-primary-dark">Contact Us</p>
            <p className="p2-r text-secondary-dark">
              For any questions or concerns about these terms, please contact us at{" "}
              <a href="mailto:support@superworkday.com" className="text-blue-600">
                support@superworkday.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Terms;
