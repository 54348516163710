import { useEffect } from "react";
import { useParams } from "react-router-dom";
import apps from "../data/apps";

const Demo = () => {
  const { appId } = useParams();
  const { demoVideoId } = apps[appId];

  useEffect(() => {
    window.location.href = `https://www.youtube.com/watch?v=${demoVideoId}`;
  }, []);

  return null;
};

export default Demo;
