import { Link } from "react-router-dom";

const AppInfo = ({ name, description, image, reverse }) => {
  return (
    <div className={`w-full flex gap-20 py-10 ${reverse ? "flex-row-reverse" : ""}`}>
      <div className="w-[514px] flex flex-col justify-between gap-8">
        <h3 className="text-white">{name}</h3>
        <p className="p2-r text-white">{description}</p>
        <Link
          to={`/${name.toLowerCase().replace(/\s+/g, "-")}`}
          className="w-fit py-5 px-10 p2-b text-white bg-primary rounded-lg"
        >
          Learn More
        </Link>
      </div>
      <div
        className={`w-1/2 rounded-3xl ${
          reverse ? "shadow-[-10px_10px_0px_#ffc037]" : "shadow-[10px_10px_0px_#ffc037]"
        }`}
      >
        <img src={image} alt={name} className="rounded-xl" />
      </div>
    </div>
  );
};

export default AppInfo;
