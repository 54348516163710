import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="flex flex-col gap-7 py-6 px-8 text-primary-dark bg-white rounded-lg cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex justify-between items-center">
        <p className="p2-b">{question}</p>
        <FaChevronDown className={`transition-transform ${isOpen ? "rotate-180" : ""}`} />
      </div>

      {isOpen && <p className="p2-r">{answer}</p>}
    </div>
  );
};

export default FAQ;
