import gcsData from "./gcsData";
import saData from "./saData";
import srtData from "./srtData";

const apps = {
  "schedule-recurring-tasks": srtData,
  "google-calendar-sync": gcsData,
  "special-automations": saData,
};

export default apps;
